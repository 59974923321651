import crud from "@/store/crud";

export default crud("/api/v1/organizations", {
  namespaced: true,
  state: {
    contacts: {},
    locations: {},
    serviceProviders: {}
  },
  mutations: {
    contacts(state, contacts) {
      state.contacts[contacts.uid] = contacts.list;
    },
    locations(state, locations) {
      state.locations[locations.uid] = locations.list;
    },
    serviceProviders(state, serviceProviders) {
      state.serviceProviders[serviceProviders.uid] = serviceProviders.list;
    }
  },
  getters: {
    get: state => () => state.entities
  },
  actions: {
    contacts(context, uid) {
      return new Promise((resolve, reject) => {
        if (context.state.contacts[uid]) {
          resolve(context.state.contacts[uid]);
        } else {
          this.$http
            .get("/api/v1/organizations/" + uid + "/contacts")
            .then(r => {
              context.commit("contacts", { uid, list: r.data });
              resolve(context.state.contacts[uid]);
            })
            .catch(err => reject(err));
        }
      });
    },
    locations(context, uid) {
      return new Promise((resolve, reject) => {
        if (context.state.locations[uid]) {
          resolve(context.state.locations[uid]);
        } else {
          this.$http
            .get("/api/v1/organizations/" + uid + "/locations")
            .then(r => {
              context.commit("locations", { uid, list: r.data });
              resolve(context.state.locations[uid]);
            })
            .catch(err => reject(err));
        }
      });
    },
    serviceProviders(context, uid) {
      return new Promise((resolve, reject) => {
        if (context.state.serviceProviders[uid]) {
          resolve(context.state.serviceProviders[uid]);
        } else {
          this.$http
            .get("/api/v1/organizations/" + uid + "/serviceproviders")
            .then(r => {
              context.commit("serviceProviders", { uid, list: r.data });
              resolve(context.state.serviceProviders[uid]);
            })
            .catch(err => reject(err));
        }
      });
    }
  }
});
