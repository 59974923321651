export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    synchronize() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(
            "/api/v1/woo/synchronizations",
            {
              headers: {
                Accept: "application/json"
              }
            },
            {}
          )
          .then(response => {
            resolve(response.body);
          })
          .catch(e => reject(e));
      });
    },
    getSynchronize() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/woo/synchronizations/active")
          .then(r => resolve(r.data))
          .catch(err => reject(err));
      });
    }
  }
};
