export default [
  {
    path: "/ui/settings",
    name: "settings/general",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/settings/SettingsView.vue"
      )
  }
];
