export default [
  {
    path: "/ui/organizations",
    name: "organizations/list",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "@/views/organizations/OrganizationListView.vue"
      )
  },
  {
    path: "/ui/organizations/:uid",
    name: "organizations/details",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "@/views/organizations/OrganizationView.vue"
      ),
    props: true
  },
  {
    path: "/ui/organizations/_new",
    name: "organizations/create",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "@/views/organizations/OrganizationCreateView.vue"
      )
  },
  {
    path: "/ui/organizations/:uid/edit",
    name: "organizations/edit",
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ "@/views/organizations/OrganizationEditView.vue"
      ),
    props: true
  }
];
