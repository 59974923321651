<template>
  <v-container fluid>
    <v-layout justify-center align-center>
      <v-row justify="center">
        <v-col cols="12" align="center">
          <v-progress-circular
            :size="size"
            color="primary"
            :width="size / 10"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    xs: Boolean,
    sm: Boolean,
    md: Boolean,
    lg: Boolean,
    xl: Boolean
  },
  components: {},
  methods: {},
  computed: {
    size() {
      if (this.xs) {
        return 12;
      } else if (this.sm) {
        return 24;
      } else if (this.md) {
        return 36;
      } else if (this.lg) {
        return 64;
      } else if (this.xl) {
        return 128;
      }

      return 24;
    }
  }
});
</script>

<style></style>
