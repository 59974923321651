"use strict";

import axios from "axios";

export default {
  install(Vue) {
    let authorize = undefined;
    let authResolve = undefined;

    Vue.prototype.$http = axios.create({});

    Vue.prototype.$http.interceptors.request.use(
      request => {
        request.headers["Authorization"] =
          "Bearer " + window.sessionStorage.getItem("bearer");
        return request;
      },
      error => {
        return Promise.reject(error);
      }
    );

    Vue.prototype.$http.interceptors.response.use(
      response => response,
      error => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
          if (typeof authorize === "function") {
            if (!authResolve) {
              authResolve = authorize();
            }
            return authResolve
              .then(() => (authResolve = undefined))
              .then(() => {
                error.config.headers["Authorization"] =
                  "Bearer " + window.sessionStorage.getItem("bearer");
                return Vue.prototype.$http.request(error.config);
              });
          }
        }

        return Promise.reject(error);
      }
    );

    Vue.prototype.$http.bind = function(auth) {
      authorize = auth;
    };
  }
};
