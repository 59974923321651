import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import de from "vee-validate/dist/locale/de.json";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

localize({
  en,
  de
});

localize("de");

// loop over all rules
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule]);
}

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Add a rule.
extend("secret", {
  validate: value => value === "example",
  message: "This is not the magic word"
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});

export default ValidationProvider;
