export default [
  {
    path: "/ui/subscription/forecast",
    name: "subscriptions/forecast",
    component: () =>
      import(
        /* webpackChunkName: "forecast" */ "@/views/subscriptions/SubscriptionForecast"
      )
  }
];
