export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    forecast(context, params) {
      let url = new URL("/api/v1/subscriptions/forecast", document.baseURI);

      if (params.level) {
        url.searchParams.append("level", params.level);
      }

      if (params.from) {
        url.searchParams.append("from", params.from);
      }

      if (params.to) {
        url.searchParams.append("to", params.to);
      }

      if (params.max) {
        url.searchParams.append("max", params.max);
      }

      return this.$http.get(url);
    },
  },
};
