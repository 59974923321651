export default [
  {
    path: "/ui/contacts",
    name: "contacts/list",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */ "@/views/contacts/ContactListView.vue"
      )
  },
  {
    path: "/ui/contacts/_new",
    name: "contacts/create",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */ "@/views/contacts/ContactCreateView.vue"
      )
  },
  {
    path: "/ui/contacts/:uid/edit",
    name: "contacts/edit",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */ "@/views/contacts/ContactEditView.vue"
      ),
    props: true
  },
  {
    path: "/ui/contacts/:uid",
    name: "contacts/details",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */ "@/views/contacts/ContactView.vue"
      ),
    props: true
  }
];
