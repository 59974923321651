import Vue from "vue";

export default function (path, module) {
  if (!module) {
    module = {};
  }

  module = Object.assign(
    { state: {}, mutations: {}, actions: {}, getters: {} },
    module
  );

  function findIndex(state, uid) {
    let index = -1;
    if (state.entities) {
      index = state.entities.findIndex((c) => c.uid === uid);
    }

    return index;
  }

  function fetch(context, promise, resolve, reject) {
    promise
      .then((c) => {
        context.commit("entity", c.data);
        let index = findIndex(context.state, c.data.uid);
        resolve(context.state.entities[index]);
      })
      .catch((err) => reject(err));
  }

  return {
    namespaced: true,
    state: Object.assign(
      {
        entities: [],
      },
      module.state
    ),
    mutations: Object.assign(
      {
        entities(state, entities) {
          state.entities.splice(0, state.entities.length);
          if (entities) state.entities.push(...entities);
        },
        entity(state, entity) {
          let index = findIndex(state, entity.uid);
          if (index < 0) state.entities.push(entity);
          else Vue.set(state.entities, index, entity);
        },
        reset(state) {
          state.entities = [];
        },
      },
      module.mutations
    ),
    getters: Object.assign({}, module.getters),
    actions: Object.assign(
      {
        reset(context) {
          context.commit("reset");
          return new Promise((resolve) => {
            resolve();
          });
        },
        list(context, params) {
          if (!params) params = {};

          return new Promise((resolve, reject) => {
            var url = new URL(path, document.baseURI);
            if (params.page) url.searchParams.append("page", params.page);
            if (params.size) url.searchParams.append("size", params.size);
            if (params.order) {
              for (let i in params.order) {
                url.searchParams.append("order", params.order[i]);
              }
            }
            if (params.filters) {
              Object.keys(params.filters).forEach((k) => {
                if (Array.isArray(params.filters[k]))
                  params.filters[k].forEach((v) =>
                    url.searchParams.append(k, v)
                  );
                else url.searchParams.append(k, params.filters[k]);
              });
            }
            if (params.includes) {
              for (let i in params.includes) {
                url.searchParams.append("include", params.includes[i]);
              }
            }

            this.$http
              .get(url, {
                headers: {
                  Accept: "application/json",
                },
              })
              .then((response) => {
                if (response.data && response.data.items)
                  response.data.items.forEach((c) =>
                    context.commit("entity", c)
                  );
                else if (response.data) {
                  context.commit("entities", response.data);
                }

                resolve(response.data);
              })
              .catch((e) => reject(e));
          });
        },
        get(context, uid) {
          return new Promise((resolve, reject) => {
            let index = findIndex(context.state, uid);
            if (index < 0) {
              var url;
              if (typeof uid === "string") {
                url = new URL(path + "/" + uid, document.baseURI);
              } else {
                url = new URL(path + "/" + uid.uid, document.baseURI);

                if (uid.includes) {
                  for (let i in uid.includes) {
                    url.searchParams.append("include", uid.includes[i]);
                  }
                }
              }

              fetch(context, this.$http.get(url), resolve, reject);
            } else {
              resolve(context.state.entities[index]);
            }
          });
        },
        create(context, entity) {
          return new Promise((resolve, reject) => {
            fetch(context, this.$http.post(path, entity), resolve, reject);
          });
        },
        update(context, entity) {
          return new Promise((resolve, reject) => {
            fetch(
              context,
              this.$http.put(path + "/" + entity.uid, entity),
              resolve,
              reject
            );
          });
        },
        delete(context, entity) {
          return new Promise((resolve, reject) => {
            this.$http
              .delete(path + "/" + entity.uid)
              .then((r) => resolve(r))
              .catch((err) => reject(err));
          });
        },
      },
      module.actions
    ),
  };
}
