export default [
  {
    path: "/ui/locations",
    name: "locations/list",
    component: () =>
      import(
        /* webpackChunkName: "locations" */ "@/views/locations/LocationsListView.vue"
      )
  },
  {
    path: "/ui/locations/:uid",
    name: "locations/details",
    component: () =>
      import(
        /* webpackChunkName: "locations" */ "@/views/locations/LocationView.vue"
      ),
    props: true
  }
];
