<template>
  <span :title="$d(date_, 'long')">{{ ago }}</span>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    date: {
      type: [String, Date, Number],
      required: true
    },
    from: {
      type: [String, Date, Number],
      required: false,
      default: () => Date.now()
    }
  },
  data() {
    return {};
  },
  computed: {
    date_() {
      return this.convert_(this.date);
    },
    ago() {
      return this.ago_(this.date_, this.convert_(this.from));
    }
  },
  mounted() {},
  methods: {
    convert_(date) {
      if (date === "undefined") {
        return undefined;
      } else if (typeof date === "object" && date instanceof Date) {
        return date;
      } else if (typeof date === "number" || date instanceof Number) {
        return new Date(date);
      } else {
        let d = new Date(date);
        let current = new Date();

        d = new Date(d.getTime() - current.getTimezoneOffset() * 60000);

        return d;
      }
    },
    ago_(from, to) {
      var val = 0 | ((to - from) / 1000);
      var unit;
      var length = {
        second: 60,
        minute: 60,
        hour: 24,
        day: 7
      };
      var result;

      for (unit in length) {
        result = val % length[unit];
        if (!(val = 0 | (val / length[unit]))) {
          return this.$tc("ago." + unit, result);
        }
      }

      return this.$d(from, "short");
    }
  }
});
</script>

<style></style>
