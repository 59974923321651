<template>
  <v-container fluid fill-height>
    <v-layout justify-center align-center>
      <v-flex text-xs-center>
        <div class="text-capitalize title error text-xs-center">
          Not found
          <v-icon x-large color="error--text">link_off</v-icon>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  components: {},
  methods: {},
  computed: {}
});
</script>

<style></style>
