import Vue from "vue";
import VueRouter from "vue-router";
import ContactRoutes from "./contacts";
import ErrorRoutes from "./errors";
import GlobalRoutes from "./global";
import LocationRoutes from "./locations";
import ServiceProviderRoutes from "./serviceProviders";
import SettingsRoutes from "./settings";
import SubscriptionRoutes from "./subscriptions";
import Organizations from "./organizations";

Vue.use(VueRouter);

const routes = [
  ...GlobalRoutes,
  ...ContactRoutes,
  ...LocationRoutes,
  ...ServiceProviderRoutes,
  ...SettingsRoutes,
  ...SubscriptionRoutes,
  ...Organizations,

  //Error routes has to be last
  ...ErrorRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
