<template>
  <v-container fluid>
    <v-layout justify-center align-center>
      <v-row align="center">
        <v-col cols="12" align="center" v-if="!msg">
          <div v-html="$t('error.' + type_, [param_])"></div>
        </v-col>
        <v-col cols="12" align="center" v-else>
          <div>{{ msg }}</div>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    error: [Error, Object],
    type: String,
    message: String
  },
  data() {
    return {
      type_: this.type,
      param_: this.error,
      msg: null
    };
  },
  mounted() {
    this.parse();
  },
  watch: {
    error: "parse"
  },
  components: {},
  methods: {
    parse() {
      if (this.error && this.error.response && this.error.response.status) {
        if (this.error.response.status === 404) {
          this.type_ = "not_found";
          this.param_ = this.error.response.url;
        } else if (this.error.response.status === 500) {
          this.type_ = "server_error";
          this.param_ = this.error.message;
        }
      }

      if (this.error && this.error.message) {
        this.msg = this.error.message;
      }

      if (this.type === null || this.type === undefined) {
        this.type_ = "unhandled";
      }
    }
  },
  computed: {}
});
</script>

<style></style>
