import Vue from "vue";
import App from "./App.vue";
import SharedComponents from "./components/shared";
import "./main.scss";
import cognito from "./plugins/aws-cognito";
import Http from "./plugins/http";
import i18n from "./plugins/i18n";
import ValidationProvider from "./plugins/vee-validate";
import vuetify from "./plugins/vuetify";
import Store from "./plugins/vuex";
import router from "./router";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.config.productionTip = process.env.NODE_ENV !== "production";

Vue.use(Http);
Vue.use(SharedComponents);

Vue.component("ValidationProvider", ValidationProvider);

fetch("/api/v1/infos")
  .then((r) => {
    return r.json();
  })
  .then((config) => {
    if (!window.cognito) window.cognito = {};
    window.cognito.config = config;
    var store = Store(Vue.prototype.$http);
    cognito(store);

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#maximi-app");
  });
