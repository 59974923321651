import crud from "@/store/crud";

export default crud("/api/v1/serviceproviders", {
  namespaced: true,
  state: {
    serviceProviderTypes: null
  },
  mutations: {
    serviceProviderTypes(state, serviceProviderTypes) {
      if (!state.serviceProviderTypes) {
        state.serviceProviderTypes = [];
      } else {
        state.serviceProviderTypes.splice(0, state.serviceProviderTypes.length);
      }
      if (serviceProviderTypes)
        state.serviceProviderTypes.push(...serviceProviderTypes);
    }
  },
  actions: {
    types(context) {
      return new Promise((resolve, reject) => {
        if (context.state.serviceProviderTypes) {
          resolve(context.state.serviceProviderTypes);
        } else {
          this.$http
            .get("/api/v1/serviceproviders/types")
            .then(r => {
              context.commit("serviceProviderTypes", r.data);
              resolve(context.state.serviceProviderTypes);
            })
            .catch(err => reject(err));
        }
      });
    },
    setOrganization(context, command) {
      return new Promise((resolve, reject) => {
        this.$http
          .put(
            "/api/v1/serviceproviders/" + command.uid + "/organization",
            command.organization
          )
          .then(r => {
            context.commit("entity", r.data);
            resolve(context.state.entities[command.uid]);
          })
          .catch(err => reject(err));
      });
    },
    removeOrganization(context, uid) {
      return new Promise((resolve, reject) => {
        this.$http
          .delete("/api/v1/serviceproviders/" + uid + "/organization")
          .then(r => {
            context.commit("entity", r.data);
            resolve(context.state.entities[uid]);
          })
          .catch(err => reject(err));
      });
    }
  }
});
