<template>
  <app-error v-if="error" type="loading" :error="error"></app-error>
  <app-loader
    v-else-if="loading"
    :xs="xs"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
  ></app-loader>
  <div v-else><slot></slot></div>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
    error: { type: Error, required: false },
    xs: Boolean,
    sm: Boolean,
    md: Boolean,
    lg: Boolean,
    xl: Boolean
  }
};
</script>

<style></style>
