export default [
  {
    path: "/ui/serviceproviders",
    name: "serviceProviders/list",
    component: () =>
      import(
        /* webpackChunkName: "serviceproviders" */ "@/views/service_providers/ServiceProvidersListView.vue"
      )
  },
  {
    path: "/ui/serviceproviders/:uid",
    name: "serviceproviders/details",
    component: () =>
      import(
        /* webpackChunkName: "serviceproviders" */ "@/views/service_providers/ServiceProviderView.vue"
      ),
    props: true
  }
];
