<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary app>
      <v-list nav dense>
        <v-list-item link :to="{ name: 'contacts/list' }">
          <v-list-item-icon>
            <v-icon>contacts</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ $t("contacts.title") }}</v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'locations/list' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ $t("locations.title") }}</v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'serviceProviders/list' }">
          <v-list-item-icon>
            <v-icon>emoji_people</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t("serviceProviders.title") }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'organizations/list' }">
          <v-list-item-icon>
            <v-icon>business</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ $t("organizations.title") }}
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-3 mb-3" />
        <v-list-item link :to="{ name: 'subscriptions/forecast' }">
          <v-list-item-icon>
            <v-icon>show_chart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{
            $t("subscriptions.forecast.title")
          }}</v-list-item-content>
        </v-list-item>
        <v-divider class="mt-3 mb-3" />
        <v-list-item link :to="{ name: 'settings/general' }">
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ $t("settings.title") }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color="primary"
      app
      scroll-off-screen
      :scroll-threshold="50"
      elevation
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ $t("app.title") }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="goDark = !goDark">
        <v-icon v-if="theme">toggle_on</v-icon>
        <v-icon v-else>toggle_off</v-icon>
      </v-btn>
      <v-btn icon @click="logout()">
        <v-icon>power_settings_new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main v-if="ready">
      <transition name="fade" mode="out-in" appear>
        <router-view :key="$route.path"></router-view>
      </transition>
    </v-main>

    <v-main v-else>
      <app-loader></app-loader>
    </v-main>

    <v-footer app>
      <span>&copy; aumasoft 2019</span>
    </v-footer>

    <authorize ref="authorize"></authorize>
    <confirm ref="confirm"></confirm>
  </v-app>
</template>

<script>
import Authorize from "./components/auth/Authorize";

export default {
  name: "App",

  components: {
    Authorize,
  },
  mounted() {
    this.$http.bind(this.$refs.authorize.authorize);
    this.$root.$confirm = this.$refs.confirm.open;
  },
  data: () => ({
    goDark: false,
    signIn: true,
    drawer: false,
    prefetched: true,
  }),
  watch: {
    goDark: function (val) {
      this.$vuetify.theme.dark = val;
    },
  },
  computed: {
    theme() {
      return this.goDark;
    },
    ready() {
      return this.signIn && this.prefetched;
    },
  },
  methods: {
    login() {
      this.$refs.authorize.authorize().then(() => (this.signIn = true));
    },
    logout() {
      window.sessionStorage.removeItem("bearer");
      this.signIn = false;
      this.$store
        .dispatch("auth/signOut")
        .catch(() => this.login())
        .finally(() => this.login());
    },
  },
};
</script>
