import crud from "@/store/crud";
import contacts from "@/store/contacts";
import locations from "@/store/locations";
import serviceProviders from "./serviceProviders";
import settings from "./settings";
import subscriptions from "@/store/subscriptions";
import organizations from "@/store/organizations";

export default {
  strict: process.env.NODE_ENV !== "production",
  modules: {
    contacts,
    organizations,
    locations,
    serviceProviders,
    settings,
    topics: crud("/api/v1/topics"),
    subscriptions
  },
  actions: {
    reset(context) {
      //Contact linked in other resources, ensure that all fetched at first
      return context.dispatch("contacts/reset").then(() => {
        return Promise.all([
          context.dispatch("locations/reset"),
          context.dispatch("serviceProviders/reset"),
          context.dispatch("topics/reset")
        ]);
      });
    }
  }
};
