import Store from "@/store";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default function InitStore(http) {
  const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: Store.modules,
    actions: Store.actions
  });
  store.$http = http;

  return store;
}
