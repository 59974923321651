<template>
  <v-dialog v-model="dialog" persistent width="50%">
    <ValidationObserver slim v-slot="{ invalid }" ref="observer">
      <v-card>
        <v-card-title class="headline">
          {{ $t("auth.newPasswordTitle") }}
        </v-card-title>
        <v-card-text>
          <app-error :error="error" v-if="error" />

          <v-form key="password-form" @submit.prevent="signIn()">
            <v-container>
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('auth.password')"
                    vid="password"
                  >
                    <v-text-field
                      prepend-icon="secret"
                      v-model.trim="newPassword"
                      :label="$t('auth.password')"
                      type="password"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required|password:@password"
                    v-slot="{ errors }"
                    :name="$t('auth.password')"
                    vid="confirm"
                  >
                    <v-text-field
                      prepend-icon="secret"
                      v-model="confirmPassword"
                      :label="$t('auth.password')"
                      type="password"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="change()" :disabled="invalid">
            {{ $t("auth.change") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      error: null,
      newPassword: null,
      confirmPassword: null,
      user: null
    };
  },
  methods: {
    change() {
      this.user.completeNewPasswordChallenge(
        this.newPassword,
        this.user.challengeParam.requiredAttributes,
        {
          onFailure: err => {
            this.error = err;
          },
          onSuccess: user => {
            this.resolve(user);
          }
        }
      );
    },
    open(user) {
      return new Promise(resolve => {
        this.resolve = resolve;
        this.dialog = true;
        this.user = user;
      });
    }
  }
};
</script>

<style></style>
