import TimeAgo from "./TimeAgo.vue";
import AppError from "./AppError.vue";
import AppLoader from "./AppLoader.vue";
import Confirm from "./Confirm.vue";
import DateTimePicker from "./DateTimePicker.vue";
import AppLazyContainer from "./AppLazyContainer.vue";

export default {
  version: "__VERSION__",
  install(vm) {
    vm.component("time-ago", TimeAgo);
    vm.component("app-error", AppError);
    vm.component("app-loader", AppLoader);
    vm.component("app-lazy-container", AppLazyContainer);
    vm.component("confirm", Confirm);
    vm.component("date-time-picker", DateTimePicker);
  }
};
