<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-menu
          v-model="datemenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          :disabled="disabled"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedDate"
              :label="datelabel"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="datemenu = false"
            scrollable
            no-title
            :disabled="disabled"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="timemenu"
          v-model="timemenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="time"
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
          :disabled="disabled"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="time"
              :label="timelabel"
              prepend-icon="access_time"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="time"
            full-width
            @click:minute="$refs.timemenu.save(time)"
            format="24hr"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Date],
      required: true
    },
    datelabel: { type: String },
    timelabel: { type: String },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      datemenu: false,
      timemenu: false
    };
  },
  computed: {
    dateTime() {
      if (typeof this.value === "string" || this.value instanceof String) {
        return new Date(Date.parse(this.value));
      }

      return this.value;
    },
    date: {
      get() {
        return this.dateTime.toISOString().substring(0, 10);
      },
      set(d) {
        this.setValue(d, this.time);
      }
    },
    time: {
      get() {
        let h = this.dateTime.getHours();
        let m = this.dateTime.getMinutes();
        return (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m);
      },
      set(t) {
        this.setValue(this.date, t);
      }
    },
    formattedDate() {
      return this.$d(this.dateTime, "short");
    }
  },
  methods: {
    setValue(date, time) {
      let d = new Date(Date.parse(date));
      let t = time.split(":");
      d.setHours(parseInt(t[0]));
      d.setMinutes(parseInt(t[1]));

      this.$emit("input", d);
    }
  }
};
</script>

<style></style>
