<template>
  <v-dialog v-model="dialog" persistent width="50%" @keydown.esc="cancel()">
    <ValidationObserver slim v-slot="{ invalid }" ref="observer">
      <v-card v-if="!sent">
        <v-card-title class="headline">
          {{ $t("auth.forgotPassword") }}
        </v-card-title>
        <v-card-text>
          <app-error :error="error" v-if="error" />
          <v-form key="forgot-password-form" @submit.prevent="send()">
            <v-container>
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('auth.email')"
                    vid="email"
                  >
                    <v-text-field
                      prepend-icon="email"
                      v-model.trim="email"
                      :label="$t('auth.email')"
                      type="text"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="send()" :disabled="invalid">
            {{ $t("auth.resetPassword") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="headline">
          {{ $t("auth.newPasswordTitle") }}
        </v-card-title>
        <v-card-text>
          <app-error :error="error" v-if="error" />

          <v-form key="change-password-form" @submit.prevent="change()">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('auth.code')"
                    vid="code"
                  >
                    <v-text-field
                      prepend-icon="code"
                      v-model.trim="code"
                      :label="$t('auth.code')"
                      type="text"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('auth.password')"
                    vid="password"
                  >
                    <v-text-field
                      prepend-icon="secret"
                      v-model.trim="password"
                      :label="$t('auth.password')"
                      type="password"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required|password:@password"
                    v-slot="{ errors }"
                    :name="$t('auth.password')"
                    vid="confirm"
                  >
                    <v-text-field
                      prepend-icon="secret"
                      v-model="confirm"
                      :label="$t('auth.password')"
                      type="password"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="change()" :disabled="invalid">
            {{ $t("auth.resetPassword") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resolve: null,
      dialog: false,
      sent: false,
      code: null,
      email: null,
      password: null,
      confirm: null,
      error: null
    };
  },
  methods: {
    reset() {
      return new Promise(resolve => {
        this.resolve = resolve;
        this.dialog = true;
        this.email = null;
        this.code = null;
        this.password = null;
        this.confirm = null;
        this.sent = false;
        this.error = null;
        if (this.$refs.observer) this.$refs.observer.reset();
      });
    },
    send() {
      this.$store
        .dispatch("auth/forgotPassword", { username: this.email })
        .then(() => {
          this.sent = true;
          this.error = null;
        })
        .catch(err => (this.error = err));
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    change() {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.$store
            .dispatch("auth/changePassword", {
              username: this.email,
              code: this.code,
              newPassword: this.password
            })
            .then(() => {
              this.resolve({
                email: this.email,
                password: this.password
              });
              this.dialog = false;
            })
            .catch(err => (this.error = err));
        }
      });
    }
  }
};
</script>

<style></style>
