export default [
  { name: "none", path: "", redirect: "/ui" },
  { name: "root", path: "/", redirect: "/ui" },
  { name: "defview", path: "/ui", redirect: "/ui/contacts" },

  {
    path: "/ui/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue")
  }
];
