<template>
  <v-dialog v-model="dialog" persistent width="50%">
    <ValidationObserver slim v-slot="{ invalid }" ref="observer">
      <v-form key="sigin-form" @submit.prevent="signIn()">
        <v-card>
          <v-card-title class="headline">
            {{ $t("auth.signInTitle") }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row v-if="credentialsFailure">
                <v-col>
                  <span class="error">{{ $t("auth.credentialsFailure") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('auth.email')"
                    vid="email"
                  >
                    <v-text-field
                      prepend-icon="email"
                      v-model.trim="email"
                      :label="$t('auth.email')"
                      type="text"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('auth.password')"
                    vid="password"
                  >
                    <v-text-field
                      prepend-icon="secret"
                      v-model="password"
                      :label="$t('auth.password')"
                      type="password"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div>
              <v-btn @click="signIn()" type="submit" :disabled="invalid">
                {{ $t("auth.signIn") }}</v-btn
              >
              <br />
              <v-btn x-small text right @click="forgotPassword()">
                {{ $t("auth.forgotPassword") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>

    <new-password ref="newPassword"></new-password>
    <forgot-password ref="forgotPassword"></forgot-password>
  </v-dialog>
</template>

<script>
import NewPassword from "./NewPassword";
import ForgotPassword from "./ForgotPassword";

export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      promise: null,
      error: null,
      email: null,
      password: null,
      credentialsFailure: false
    };
  },
  components: {
    NewPassword,
    ForgotPassword
  },
  methods: {
    signIn() {
      this.error = false;
      this.credentialsFailure = false;

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.$store
            .dispatch("auth/signInUser", {
              username: this.email,
              password: this.password
            })
            .then(r => {
              if (r.challengeName === "NEW_PASSWORD_REQUIRED") {
                this.$refs.newPassword.open(r).then(() => {
                  this.fetchJwtToken();
                });
              } else {
                this.fetchJwtToken();
              }
            })
            .catch(err => {
              if (err.code === "NotAuthorizedException") {
                this.credentialsFailure = true;
              } else {
                this.error = err;
              }
            });
        }
      });
    },
    forgotPassword() {
      this.$refs.forgotPassword.reset().then(r => {
        this.email = r.email;
        this.password = r.password;
        this.$nextTick().then(() => this.signIn());
      });
    },
    fetchJwtToken() {
      this.$store
        .dispatch("auth/fetchJwtToken")
        .then(t => {
          window.sessionStorage.setItem("bearer", t);
          this.dialog = false;
          let resolve = this.resolve;
          this.resolve = null;
          this.promise = null;
          if (resolve) {
            resolve();
          }
          this.password = null;
          if (this.$refs.observer) this.$refs.observer.reset();
        })
        .catch(err => {
          if (this.dialog && err === "No current user") {
            this.credentialsFailure = true;
          } else if (this.dialog) {
            this.error = err;
          } else {
            this.dialog = true;
          }
        });
    },
    authorize() {
      if (!this.promise) {
        this.promise = new Promise(resolve => {
          this.resolve = resolve;
          this.fetchJwtToken();
        });
      }

      return this.promise;
    }
  }
};
</script>

<style></style>
