import crud from "@/store/crud";

export default crud("/api/v1/contacts", {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {
    get: state => () => state.entities
  },
  actions: {
    setOrganization(context, command) {
      return new Promise((resolve, reject) => {
        this.$http
          .put(
            "/api/v1/contacts/" + command.uid + "/organization",
            command.organization
          )
          .then(r => {
            context.commit("entity", r.data);
            resolve(context.state.entities[command.uid]);
          })
          .catch(err => reject(err));
      });
    },
    removeOrganization(context, uid) {
      return new Promise((resolve, reject) => {
        this.$http
          .delete("/api/v1/contacts/" + uid + "/organization")
          .then(r => {
            context.commit("entity", r.data);
            resolve(context.state.entities[uid]);
          })
          .catch(err => reject(err));
      });
    }
  }
});
